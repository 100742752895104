const scroller = new Scroller();

const handler = {
    DOWN: (evt) => {
        scroller.scrollToNext(evt.target);
    },
    SLIDE_1: () => {
        scroller.scrollTo(0);
    },
    SLIDE_2: () => {
        scroller.scrollTo(1);
    },
    SLIDE_3: () => {
        scroller.scrollTo(2);
    },
    SLIDE_4: () => {
        scroller.scrollTo(4);
    },
    SLIDE_5: () => {
        scroller.scrollTo(6);
    },
    SLIDE_6: () => {
        scroller.scrollTo(8);
    },
    MORE: (evt) => {
        scroller.toggleNext(evt.target);
    },
    CLOSE: (evt) => {
        scroller.slideUp(evt.target);
    }
};

[...document.querySelectorAll("#SLIDE_1, #TOP")].forEach((link) =>
    link.addEventListener("click", handler.SLIDE_1)
);
[...document.querySelectorAll("#SLIDE_2")].forEach((link) =>
    link.addEventListener("click", handler.SLIDE_2)
);
[...document.querySelectorAll("#SLIDE_3")].forEach((link) =>
    link.addEventListener("click", handler.SLIDE_3)
);
[...document.querySelectorAll("#NEXT_POPUP")].forEach((link) =>
    link.addEventListener("click", handler.NEXT_POPUP)
);
[...document.querySelectorAll("#SLIDE_4")].forEach((link) =>
    link.addEventListener("click", handler.SLIDE_4)
);
[...document.querySelectorAll("#SLIDE_5")].forEach((link) =>
    link.addEventListener("click", handler.SLIDE_5)
);
[...document.querySelectorAll("#SLIDE_6, #KONTAKT_BUTTON")].forEach((link) =>
    link.addEventListener("click", handler.SLIDE_6)
);
[...document.querySelectorAll("#DOWN")].forEach((link) =>
    link.addEventListener("click", handler.DOWN)
);
[...document.querySelectorAll("#MORE")].forEach((link) =>
    link.addEventListener("click", handler.MORE)
);
[...document.querySelectorAll("#CLOSE")].forEach((link) =>
    link.addEventListener("click", handler.CLOSE)
);